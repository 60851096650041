import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    :root{
        --primary-color: #007bff;
        --primary-color-light: #057FFF;
        --secondary-color: #6c757d;
        --background-dark-color: #10121A;
        --background-dark-grey: #191D2B;
        --border-color: #2e344e;
        --color-white: white;
        --background-light-color: #F1F1F1;
        --background-light-color-2: rgba(3,127,255,.3);
        --white-color: #FFF;
        --font-light-color: #a4acc4;
        --font-dark-color: #313131;
        --font-dark-color-2: #151515;
        --sidebar-dark-color: #191D2B;
        --scrollbar-bg-color: #383838;
        --scrollbar-thump-color: #6b6b6b;
        --scrollbar-track-color: #383838;
        --typewriterSpeed: 3s;
        --typewriterCharacters:24;
        --surface-color: #fff;
        --curve: 40;  
        --background-dark: #2d3548;
        --text-light: rgba(255,255,255,0.6);
        --text-lighter: rgba(255,255,255,0.9);
        --spacing-s: 8px;
        --spacing-m: 16px;
        --spacing-l: 24px;
        --spacing-xl: 32px;
        --spacing-xxl: 64px;
        --width-container: 1200px;

        //---- CONTACT STYLES ----//
        --bg: #dcdde1;     
        --color-icon: #535c68;  
        --social-icon1: #e4405f;
        --social-icon2: #3b5999;
        --social-icon3: #e4405f;
        --social-icon4: #cd201f;
        --social-icon5: #0077B5;
    
    }
    *{
        padding: 0;
        margin: 0;
        box-sizing: border-box;
        font-family: 'Montserrat','Martel', sans-serif;
        list-style: none;
        text-decoration: none;
        font-size: 1.2rem;
    }
    body{
        background-color: var(--background-dark-color);
        color: var(--font-light-color);
    }
    a{
        font-family: inherit;
        color: inherit;
        font-size: inherit;
    }
    h1,h2,h3,h4,h5{
        font-weight:700;
    }
}
    
    `;
export default GlobalStyle;
